import React from "react"
import { Link } from "@components/common/LangLink"

export default () => {
  return (
    <>
      <h1>Page not found</h1>
      <Link to="/">Go Home</Link>
    </>
  )
}
